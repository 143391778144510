import React from 'react';
import { Link } from 'react-router-dom';
import './largevideo.css';


const LargeVideo = ({ lgvideolink}) => {
    return (
        <div className='center-media__container'>
            <div className="video__wrap">
                <video src={lgvideolink} alt='' autoPlay loop controls="true"/>
            </div>
        </div>
    );
}

export default LargeVideo;
