import React, { useRef, useEffect } from 'react'
import { Col } from 'reactstrap'
import { init } from 'ityped'
import "./social.css"
import Demo from '.././../assets/demo.png'
import { Navbar } from '../../components'
import { Footer } from '../../containers'

const Social = () => {
    const superRef = useRef()
    useEffect(()=>{
        init(superRef.current, {
            backDelay: 1200,
            showCursor: true,
            strings:[
                'Twitter',
                'Instagram',
                "LinkedIn",
                "Youtube", 
                "Facebook"

            ]
        })
    }, [])
    return <div className='social'>
    <Navbar/>
    <section className='super__section' id="home">
        <Col d-flex flex-row lg='6' md='6' w="6" > 
            <div className='super__content'>
                <p2 className='mb-20'>Thank you for your interest in following the Etheware Mission.</p2>
                <h3 className='mb-4'>You can now watch the whole mission closely  </h3>
                <h2 className='super__title mb-5'>Through <span ref={superRef}></span></h2>
                <p>Follow us, keep your notifications, and interact on for special access to private events, opportunities and giveaways. Always be the first to know.
                </p>
            </div>
            <Col flex-row lg='6' md='10' sm="5" className='super__btns'>
                <a href="https://www.twitter.com/ethewaretech"><button className='btn-contact_btn'>Etheware's Twitter</button></a>
                <a href="https://www.twitter.com/ethewaresupport"><button className='btn-contact_btn'>Support Twitter</button></a>
                <a href="https://www.instagram.com/ethewaretech"><button className='btn-contact_btn'>Etheware's Instagram</button></a>
                <a href="https://www.linkedin.com/company/etheware/"><button className='btn-contact_btn'>Etheware's LinkedIn</button></a>
                <a href="https://www.youtube.com/channel/UCpV8SAxy3dKwS58WMFZd3Tg"><button className='btn-contact_btn'>Etheware's Youtube</button></a>
                <a href="https://www.facebook.com/etheware"><button className='btn-contact_btn'>Etheware's Facebook</button></a>
                <a href="https://www.twitter.com/crystalbadu"><button className='btn-contact_btn'>The CEO's Twitter</button></a>
            </Col>
        </Col>
        <Col d-flex lg='0' md='1'>
            <Col lg="4" md="5" sm="15">
            <img 
                className='ty__img'
                src={Demo} 
                alt="Etheware Project Demo"
                loading="lazy"
            />
            </Col>
        </Col>
  </section>
  <Footer/>
 </div>
    
}

export default Social