import React from 'react';
import { LeftMedia, Media, Navbar } from '../../components';
import {Footer } from '../../containers';
import Textbox from '../../components/textBox/textBox';


const Company = () => {
  return (
      <div className='home'>  
        <Navbar />
        <Textbox  
            title={"Who Are We?"}
            text={"Etheware was founded in San Diego, CA in 2022 by founder and researcher Crystal Badu with a central a mission to create a fundamentally unique software company that not only develops the best products, but is always ahead in innovating and testing the latest technology. Etheware's passion for building advanced solutions reflects every solution made. From the methodology employed, to the development process followed, Etheware core value lies in the fact we write each line of code with meaning. Etheware believe in a better the tomorrow for everyone."}
        />
        <LeftMedia 
          top={"Learn how we think, perform and build the leading innovations of tomorrow."}
          header={"The Etheware Mission"}
          paragraph={"Etheware develops solutions that bridge the gap between symbiotic AI, and human interaction to make it possible for anyone to interact with AI and build next-gen applications that further the human race."}
          video__link={require("../../assets/company.mp4")}
          button={"Connect With Us"}
          btn__link={"/socials"}
        />
        <Media 
          topline={"Etheware & helping to build the future in then next decade"}
          header={"Next-Gen Integrations"}
          paragraph={"Etheware recognizes that although entrepreneurs, small to medium-sized businesses, and startups cannot access the rooms of Tech powerhouses at ease to learn information for their enterprise. They do, deserve access to advanced technology just like the best leaders in the industry. As a result of this imbalance, Etheware's wealth of expertise in offshore software development and project management rose to mission of building out the future. Etheware was founded formally in 2022 to deliver next-generation IT solutions. In the next 10 years, Etheware will scale out to bring revolutionary innovations that benefit us all."}
          lin1={require("../../assets/tabletapp.png")}
          btnlink={"/next-gen"}
          btntxt={"Learn More"}
        />
        <LeftMedia 
          topline={"Shape the world and make a difference"}
          header={"Strategic Partnerships"}
          paragraph={"Any company's performance is strongly related to it's diverse backgrounds, abilities, and expertise of its personnel. We respect such distinctions at Etheware and use them to promote diversity and drive continuing commitments to our workers and community. Etheware offers a number of key collaborations and initiatives that help clients fulfill their diversification, Development, and skilling objectives."}
          video__link={require("../../assets/partnerships.mp4")}
          button={"Contact Us"}
          btn__link={"/contact"}
        />
        <LeftMedia 
          topline={"Shape the world and make a difference"}
          header={"Careers"}
          paragraph={"Etheware plans to build products that break barriers in technology and our reality. The future of technology is going to be led by solutions that can scale over time built on stable foundations such as the the powerful Etheware solutions. As a startup we are constantly growing in staff and capabilities in order to lead the next generation of industry leading innovations. We have positions open for candidates in a variety of sectors, click below to learn more."}
          video__link={require("../../assets/video.mp4")}
          button={"Connect With Us"}
          btn__link={"/socials"}
        />
        <Footer />
      </div>
  );
}

export default Company;