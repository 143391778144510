import React from 'react';
import './footer.css';

const Footer = () => (
  <div className="footer section__padding">
    <div className="footer-links">
      <div className="footer-links_div">
        <h4>Shop and Learn</h4>
        <p><a href="/store">Store</a></p>
        <p><a href="/product-details?service=websites&plan=earth">Earth</a></p>
        <p><a href="/product-details?service=websites&plan=bronze">Bronze</a></p>
        <p><a href="/product-details?service=websites&plan=silver">Silver</a></p>
        <p><a href="/product-details?service=websites&plan=gold">Gold</a></p>
        <p><a href="/product-details?service=websites&plan=diamond-peak">Diamond Peak</a></p>
        <p><a href="/product-details?service=websites&plan=gold">Platinum Unlimited</a></p>
        <p><a href="/how-it-works">How It Works</a></p> 
      </div>
      <div className="footer-links_div">
        <h4>Why Etheware</h4>
        <p><a href="/why-choose-etheware">Why Choose Etheware</a></p>
        <p><a href="/etheware-difference">The Etheware Difference</a></p>
        <li><p><a href="/company">Company</a></p></li>
      </div>
      <div className="footer-links_div">
        <h4>Account</h4>
        <p><a href="/order-history">Order History</a></p>
        <p><a href="/profile-settings">Manage Your Etheware Account</a></p>
        <p><a href="/sign-in">Etheware Cloud Account</a></p>
        <p><a href="/sign-up">Create an Account</a></p>
      </div>
      
      <div className="footer-links_div">
        <h4>Etheware Store</h4>
        <p><a href="/store">Store</a></p>
        <p><a href="/support">Etheware Support</a></p>
        <p><a href="/trade-in">Etheware Trade-In</a></p>
      </div>
      {/* <div className="footer-links_div">
        <h4>Etheware Values</h4>
        <p><a href="/">Accessibility</a></p>
        <p><a href="/">Education</a></p>
        <p><a href="/">Environment</a></p>
        <p><a href="/">Inclusion and Diversity</a></p>
        <p><a href="/">Privacy</a></p>
        <p><a href="/">Racial Equity and Justice</a></p>
        <p><a href="/">Developer Responsibility</a></p>
      </div> */}
      <div className="footer-links_div">
        <h4>About Etheware</h4>
        <p><a href={"https://www.linkedin.com/company/etheware/"}>Career Opportunities</a></p>
        <p><a href={"/company"}>Company</a></p>
        <p><a href={"/contact"}>Contact Etheware</a></p>
      </div>

      <div className="footer-links_div">
        <h4>Get In Touch</h4>
        <p>More ways to shop than online. Call 1-619-872-3038</p>
        <p><a href={"mailto:solutions@etheware.com"}>or Email Etheware</a></p>
        <h6>United States</h6>
      </div>
    </div>
    <div className="footer-copyright">
      <p>@2022 Etheware.com. All rights reserved.</p>
    </div>
    <div className="footer__buttons">
      <p><a href="/legal">Privacy Policy</a></p>
      <div className="icon__div"/>
      <p><a href="/legal">Terms of Use</a></p>
      <div className="icon__div"/>
      <p><a href="/legal">Sales and Refunds</a></p>
      <div className="icon__div"/>
      <p><a href="/legal">Legal</a></p>
      <div className="icon__div"/>
      <p><a href="/sitemap">Site Map</a></p>
    </div>
      <div className="footer-paragraph">
        <p>Etheware Subscription Plans automatically renew until cancelled. An account is required to request maintenance. After purchasing your subscription plan, you will be contacted by the Etheware Subscription Management team in to begin your services.</p>
      </div>
      <div className="footer-links_logo">
        <p>Copyright © Etheware LLC.<br /> All Rights Reserved.</p>
    </div>
  </div>
);

export default Footer;