import * as firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyBtaDQskvoBzmyMQWtUn-qUPs8rrUPXqDE",
    authDomain: "etheware-live.firebaseapp.com",
    databaseURL: "https://etheware-live-default-rtdb.firebaseio.com",
    projectId: "etheware-live",
    storageBucket: "etheware-live.appspot.com",
    messagingSenderId: "948539583365",
    appId: "1:948539583365:web:4c43f7eb8f5445686d72ec"
};

firebase.initializeApp(firebaseConfig);

export default firebase